var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":""}},[_c('b-row',{staticClass:"justify-content-between align-items-center mx-0"},[_c('b-col',{staticClass:"align-items-center"},[_c('b-row',{staticClass:"my-1 text-right mt-2 d-flex justify-content-start"},[_c('b-form-group',[_c('b-form-input',{attrs:{"type":"text","placeholder":"Buscar item"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('b-col',{staticClass:"align-items-center d-flex justify-content-end"},[(_vm.currentUser.is_admin)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('/incluir-novo-colaborador')}}},[_vm._v(" Novo usuário ")]):_vm._e()],1)],1),_c('b-table',{staticClass:"text-left",attrs:{"responsive":"sm","small":"","fields":_vm.fields,"items":_vm.filter()},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('ActionsSuppliers',{attrs:{"item":item,"currentUser":_vm.currentUser,"member_id":_vm.member_id}})]}},{key:"cell(employee_name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"cell(employee_mail)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"cell(access.is_blocked)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.access.is_blocked == false ? 'Ativo' : 'Inativo')+" ")]}}])}),_c('b-pagination',{staticClass:"justify-content-center",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table"},on:{"change":_vm.getPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('div',{staticClass:"mr-2 d-flex justify-content-end"},[_c('strong',[_vm._v("Total de colaboradores: "+_vm._s(_vm.totalRows)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }